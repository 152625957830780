import { Injectable, inject } from '@angular/core';
import { ICreateUserPayload, IUpdateUserPayload } from 'app/core/auth/models/create-user-payload.model';
import { Observable, switchMap } from 'rxjs';
import { UsersService } from '../services/users.service';

@Injectable({ providedIn: 'root' })
export class UsersFacade {
  private readonly usersService = inject(UsersService);

  public users$ = this.usersService.users$;
  public staffUsers$ = this.usersService.staffUsers$;
  public usersStats$ = this.usersService.usersStats$;

  public getUsers(page = 1, pageSize = 10, email?: string, fullname?: string): void {
    this.usersService.getUsers(page, pageSize, email, fullname).subscribe();
  }

  public getStaffUsers(page = 1, pageSize = 10, email?: string, fullname?: string): void {
    this.usersService.getStaffUsers(page, pageSize, email, fullname).subscribe();
  }

  public createStaffUser(createUserPayload: ICreateUserPayload): Observable<void> {
    return this.usersService.createStaffUser(createUserPayload);
  }

  public editStaffUser(userId: string, updateUserPayload: IUpdateUserPayload): Observable<void> {
    return this.usersService.editStaffUser(userId, updateUserPayload);
  }

  public deleteStaffUser(userId: string): void {
    this.usersService
      .deleteStaffUser(userId)
      .pipe(switchMap(() => this.usersService.getStaffUsers()))
      .subscribe();
  }

  public getUsersStats(): void {
    this.usersService.getUsersStats().subscribe();
  }
}
