import { Route } from "@angular/router";
import { AuthGuard } from "app/core/auth/guards/auth.guard";
import { NoAuthGuard } from "app/core/auth/guards/noAuth.guard";
import { LayoutComponent } from "app/layout/layout.component";
import { Role } from "./core/auth/enums/role.enum";
import { RoleGuard } from "./core/auth/guards/role.guard";

export const appRoutes: Route[] = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'home' },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-in',
        loadChildren: () => import('app/modules/auth/pages/sign-in/sign-in.routes'),
      },
      {
        path: 'forgot-password',
        loadChildren: () => import('app/modules/auth/pages/forgot-password/forgot-password.routes'),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () => import('app/modules/auth/pages/sign-out/sign-out.routes'),
      },
    ],
  },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    children: [
      {
        path: 'home',
        data: {
          title: 'menu.navigation.labels.home',
        },
        loadChildren: () => import('app/modules/home/home.routes'),
      },
      {
        path: 'users',
        data: {
          roles: [Role.ADMIN, Role.SUPERADMIN, Role.SERVICE_ADMIN],
        },
        canActivate: [RoleGuard],
        canActivateChild: [RoleGuard],
        loadChildren: () => import('app/modules/users/users.routes'),
      },
      {
        path: 'transactions',
        data: {
          roles: [Role.ADMIN, Role.SUPERADMIN, Role.SERVICE_ADMIN, Role.STORE_MANAGER],
        },
        canActivate: [RoleGuard],
        canActivateChild: [RoleGuard],
        loadChildren: () => import('app/modules/transactions/transactions.routes'),
      },
      {
        path: 'payment-recharges',
        data: {
          roles: [Role.ADMIN, Role.SUPERADMIN, Role.SERVICE_ADMIN],
        },
        canActivate: [RoleGuard],
        canActivateChild: [RoleGuard],
        loadChildren: () => import('app/modules/payment-recharges/payment-rechages.routes'),
      },
      {
        path: 'stations',
        data: {
          roles: [Role.ADMIN, Role.SUPERADMIN, Role.SERVICE_ADMIN, Role.STORE_MANAGER],
        },
        canActivate: [RoleGuard],
        canActivateChild: [RoleGuard],
        loadChildren: () => import('app/modules/stations/stations.routes'),
      },
      {
        path: 'campaigns',
        data: {
          roles: [Role.SUPERADMIN, Role.ADMIN, Role.MARKETING_OPERATOR],
        },
        loadChildren: () => import('app/modules/campaigns/campaigns.routes'),
      },
      {
        path: 'audit-logs',
        data: {
          roles: [Role.SUPERADMIN, Role.ADMIN],
        },
        loadChildren: () => import('app/modules/audit/audit.routes'),
      },
      {
        path: 'legal',
        data: {
          roles: [Role.SUPERADMIN, Role.ADMIN],
        },
        loadChildren: () => import('app/modules/legal/legal.routes'),
      },
      {
        path: 'configurations',
        data: {
          roles: [Role.SUPERADMIN, Role.ADMIN, Role.MARKETING_OPERATOR],
        },
        loadChildren: () => import('app/modules/configurations/configurations.routes'),
      },
    ],
  },

  { path: '**', redirectTo: 'home' },
];
