import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import {ICreateUserPayload, IUpdateUserPayload} from 'app/core/auth/models/create-user-payload.model';
import {IUser, User} from 'app/core/auth/models/user.model';
import {Paginate} from 'app/shared/models/pagination.model';
import {ResponseDto} from 'app/shared/models/response-dto.model';
import {IStats, Stats} from 'app/shared/models/stats.model';
import {BehaviorSubject, Observable, map, tap} from 'rxjs';
import {IManagementUser, ManagementUser} from '../models/management-user.model';

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    private _users$ = new BehaviorSubject<Paginate<ManagementUser>>(null);
    private _staffUsers$ = new BehaviorSubject<Paginate<User>>(null);
    private _usersStats$ = new BehaviorSubject<Stats>(null);

    private readonly http = inject(HttpClient);

    public get users$(): Observable<Paginate<ManagementUser>> {
        return this._users$.asObservable();
    }

    public get staffUsers$(): Observable<Paginate<User>> {
        return this._staffUsers$.asObservable();
    }

    public get usersStats$(): Observable<Stats> {
        return this._usersStats$.asObservable();
    }

    public setUsers(users: Paginate<ManagementUser>) {
        this._users$.next(users);
    }

    public setStaffUsers(staffUsers: Paginate<User>) {
        this._staffUsers$.next(staffUsers);
    }

    public setUsersStats(stats: Stats) {
        this._usersStats$.next(stats);
    }

    public getUsers(page = 1, pageSize = 10, email?: string, fullname?: string): Observable<Paginate<ManagementUser>> {
        let params = new HttpParams().set('page', page).set('pageSize', pageSize);
        if(email) {
            params = params.set('email', email);
        }
        if(fullname) {
            params = params.set('name', fullname);
        }

        return this.http
            .get<ResponseDto<Paginate<IManagementUser>>>('/management-users', {
                params,
            })
            .pipe(
                map(({data}) => ({
                    ...data,
                    items: data.items.map((item) => ManagementUser.Build(item)),
                })),
                tap((users) => {
                    this.setUsers(users);
                })
            );
    }

    public getStaffUsers(page = 1, pageSize = 10, email?: string, fullname?: string): Observable<Paginate<User>> {
        let params = new HttpParams().set('page', page).set('pageSize', pageSize);

        if(email) {
            params = params.set('email', email);
        }
        if(fullname) {
            params = params.set('name', fullname);
        }

        return this.http
            .get<ResponseDto<Paginate<IUser>>>('/management-staff', {
                params,
            })
            .pipe(
                map(({data}) => ({
                    ...data,
                    items: data.items.map((item) => User.Build(item)),
                })),
                tap((staffUsers) => {
                    this.setStaffUsers(staffUsers);
                })
            );
    }

    public createStaffUser(createUserPayload: ICreateUserPayload): Observable<void> {
        return this.http.post<void>('/management-staff', createUserPayload);
    }

    public editStaffUser(userId: string, updateUserPayload: IUpdateUserPayload): Observable<void> {
        return this.http.patch<void>(`/management-staff/${userId}`, updateUserPayload);
    }

    public deleteStaffUser(userId: string): Observable<void> {
        return this.http.delete<void>(`/management-staff/${userId}`);
    }

    public generateRandomPassword(length: number): string {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+';

        let result = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);

            result += characters[randomIndex];
        }

        return result;
    }

    public getUsersStats(): Observable<Stats> {
        return this.http.get<ResponseDto<IStats>>('/management-users/stats').pipe(
            map((response) => Stats.Build(response.data)),
            tap((stats) => {
                this.setUsersStats(stats);
            })
        );
    }
}
