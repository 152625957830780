import { NgIf } from "@angular/common";
import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { RouterOutlet } from "@angular/router";
import { FuseFullscreenComponent } from "@fuse/components/fullscreen";
import { FuseLoadingBarComponent } from "@fuse/components/loading-bar";
import {
    FuseNavigationItem,
    FuseNavigationService,
    FuseVerticalNavigationComponent
} from "@fuse/components/navigation";
import { FuseMediaWatcherService } from "@fuse/services/media-watcher";
import { TranslocoModule } from "@ngneat/transloco";
import { Role } from "app/core/auth/enums/role.enum";
import { AuthFacade } from "app/core/auth/facades/auth.facade";
import { IUser } from "app/core/auth/models/user.model";
import { LanguagesComponent } from "app/layout/common/languages/languages.component";
import { MessagesComponent } from "app/layout/common/messages/messages.component";
import { NotificationsComponent } from "app/layout/common/notifications/notifications.component";
import { SearchComponent } from "app/layout/common/search/search.component";
import { ShortcutsComponent } from "app/layout/common/shortcuts/shortcuts.component";
import { UserComponent } from "app/layout/common/user/user.component";
import { BreadcrumbComponent } from "app/shared/components/breadcrumb/breadcrumb.component";
import { PageTitleComponent } from "app/shared/components/page-title/page-title.component";
import { Subject, takeUntil } from "rxjs";

@Component({
    selector: "futuristic-layout",
    templateUrl: "./futuristic.component.html",
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        FuseLoadingBarComponent,
        FuseVerticalNavigationComponent,
        UserComponent,
        MatButtonModule,
        MatIconModule,
        LanguagesComponent,
        FuseFullscreenComponent,
        SearchComponent,
        ShortcutsComponent,
        MessagesComponent,
        NotificationsComponent,
        NgIf,
        RouterOutlet,
        PageTitleComponent,
        BreadcrumbComponent,
        TranslocoModule
    ]
})
export class FuturisticLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    user: IUser;
    navigation: FuseNavigationItem[] = [
        {
            id: "home",
            title: "menu.navigation.labels.home",
            type: "basic",
            icon: "heroicons_outline:home",
            link: "/home"
        },
        {
            id: "users",
            title: "menu.navigation.labels.users",
            type: "collapsable",
            icon: "heroicons_outline:users",
            meta: {
                roles: [Role.SUPERADMIN, Role.ADMIN, Role.SERVICE_ADMIN]
            },
            children: [
                {
                    id: "users",
                    title: "menu.navigation.labels.users.pay-and-go",
                    type: "basic",
                    link: "/users/list"
                },
                {
                    id: "staff-users",
                    title: "menu.navigation.labels.users.staff",
                    type: "basic",
                    link: "/users/staff-list",
                    meta: {
                        roles: [Role.SUPERADMIN, Role.ADMIN]
                    }
                }
            ]
        },
        {
            id: "transactions",
            title: "menu.navigation.labels.transactions",
            type: "basic",
            icon: "heroicons_outline:arrow-path-rounded-square",
            link: "/transactions",
            meta: {
                roles: [Role.SUPERADMIN, Role.ADMIN, Role.SERVICE_ADMIN, Role.STORE_MANAGER]
            }
        },
        {
            id: "payment-recharges",
            title: "menu.navigation.labels.payment_recharges",
            type: "basic",
            icon: "heroicons_outline:shopping-cart",
            link: "/payment-recharges",
            meta: {
                roles: [Role.SUPERADMIN, Role.ADMIN, Role.SERVICE_ADMIN]
            }
        },
        {
            id: "stations",
            title: "menu.navigation.labels.stations",
            type: "basic",
            icon: "heroicons_outline:truck",
            link: "/stations",
            meta: {
                roles: [Role.SUPERADMIN, Role.ADMIN, Role.SERVICE_ADMIN, Role.STORE_MANAGER]
            }
        },
        {
            id: "campaigns",
            title: "menu.navigation.labels.campaigns",
            type: "basic",
            icon: "heroicons_outline:megaphone",
            link: "/campaigns",
            meta: {
                roles: [Role.SUPERADMIN, Role.ADMIN, Role.MARKETING_OPERATOR]
            }
        },
        {
            id: "audit-logs",
            title: "menu.navigation.labels.audit-logs",
            type: "basic",
            icon: "heroicons_outline:newspaper",
            link: "/audit-logs",
            meta: {
                roles: [Role.SUPERADMIN, Role.ADMIN]
            }
        },
        {
            id: "legal",
            title: "menu.navigation.labels.legal",
            type: "collapsable",
            icon: "heroicons_outline:scale",
            meta: {
                roles: [Role.SUPERADMIN, Role.ADMIN]
            },
            children: [
                {
                    id: "privacy-policy",
                    title: "menu.navigation.labels.legal.privacy-policy",
                    type: "basic",
                    link: "/legal/privacy-policy",
                    meta: {
                        roles: [Role.SUPERADMIN, Role.ADMIN]
                    }
                },
                {
                    id: "terms-and-conditions",
                    title: "menu.navigation.labels.legal.terms-and-conditions",
                    type: "basic",
                    link: "/legal/terms-and-conditions",
                    meta: {
                        roles: [Role.SUPERADMIN, Role.ADMIN]
                    }
                }
            ]
        },
        {
            id: "configurations",
            title: "menu.navigation.labels.configurations",
            type: "collapsable",
            icon: "heroicons_outline:adjustments-vertical",
            meta: {
                roles: [Role.SUPERADMIN, Role.ADMIN, Role.MARKETING_OPERATOR]
            },
            children: [
                {
                    id: "configurations.banners",
                    title: "menu.navigation.labels.banners",
                    type: "basic",
                    link: "/configurations/banners"
                },
                {
                    id: "configurations.card-filters",
                    title: "menu.navigation.labels.card-filters",
                    type: "basic",
                    link: "/configurations/card-filters"
                }
            ]
        }
    ];

    private readonly destroy$ = new Subject<void>();

    /**
     * Constructor
     */
    constructor(
        private authFacade: AuthFacade,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService
    ) {
    }

    get currentYear(): number {
        return new Date().getFullYear();
    }

    ngOnInit(): void {
        // Subscribe to the user service
        this.authFacade.user$.pipe(takeUntil(this.destroy$)).subscribe((user: IUser) => {
            this.user = user;
        });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$.pipe(takeUntil(this.destroy$)).subscribe(({ matchingAliases }) => {
            // Check if the screen is small
            this.isScreenSmall = !matchingAliases.includes("md");
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
